import Thumbnail from '../common/Thumbnail'
import ConfirmationDialog from '../common/ConfirmationDialog'
import { formatDate } from '../utils/Utils'

import { constPhotoState, constPhotoStateFamily } from '../hooks/ConstPhotoState'
import { photoSelectState } from '../hooks/PhotoSelectState'

import React, { useState, useEffect, useRef } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { IconButton, Card, CardHeader, CardContent, Button, Avatar, List, ListItem, Checkbox } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash'
import ClearIcon from '@mui/icons-material/Clear'
import ClearAllIcon from '@mui/icons-material/ClearAll'

const TrashBox = (props) => {
    const atom = constPhotoStateFamily("trashBox")
    const [state, setState] = useRecoilState(atom)
    const data = useRecoilValue(constPhotoState)
    const [photoSelect, setPhotoSelect] = useRecoilState(photoSelectState)
    const inputElement = useRef(null)
    const [allDeleteConfirm, setAllDeleteConfirm] = useState(false)
    const [deleteConfirm, setDeleteConfirm] = useState(false)
    const [restoreConfirm, setRestoreConfirm] = useState(false)

    const getData = () => {
        return state
    }

    useEffect(() => {
        setState({
            ...state,
            windowState: { x: 200, y: 400, width: 300, height: 500 },
            window: false, title: "ゴミ箱", key: "trashBox", name: "ゴミ箱", photos: data.trashBox?.map((photo) => {
                return { ...photo, date: new Date(photo.date), checked: false }
            })
        })
    }, [data])

    useEffect(() => {
        props.connector.trashBox = {
            getData: getData
        }
    }, [props.connector, state])

    const handleDropPhoto = (photo) => {
        const date = new Date()
        const type = photo.type
        const removeData = props.connector[photo.type]?.removeData
        if (removeData) {
            let photos = state.photos.slice()
            Array.from(photo.photos.values()).forEach((photo) => {
                photos.push({ type: type, date: date, photo: photo })
            })
            setState({ ...state, photos: photos })
            removeData(photo.photos)
        }
    }

    const fmt = (date) => {
        var dt = new Date()
        var y = dt.getFullYear()
        var m = ("00" + (dt.getMonth() + 1)).slice(-2)
        var d = ("00" + dt.getDate()).slice(-2)
        var h = ("00" + dt.getHours()).slice(-2)
        var M = ("00" + dt.getMinutes()).slice(-2)
        var result = y + "/" + m + "/" + d + " " + h + ":" + M
        return result

    }

    const getTypeName = (type) => {
        let datas = []
        Object.keys(props.connector).filter((k) => { return k !== "isDrag" }).forEach((k) => {
            if (props.connector[k].getData) {
                let fn = props.connector[k].getData
                let rowData = fn()
                datas[k] = rowData.title
            }
        })
        return datas[type] ? datas[type] : "-"
    }

    return (
        <div style={{ ...props.style }}>
            <ConfirmationDialog
                dialogTitle="ゴミ箱"
                bodyText={"ゴミ箱の全ての写真データを削除します。よろしいですか？"}
                open={allDeleteConfirm}
                onOk={() => {
                    setState({ ...state, photos: [] })
                    setAllDeleteConfirm(false)
                }}
                onCancel={(e) => {
                    setAllDeleteConfirm(false)
                }}
                onClose={(e) => {
                    setAllDeleteConfirm(false)
                }}
            />

            <ConfirmationDialog
                dialogTitle="ゴミ箱"
                bodyText={"ゴミ箱から指定された写真データを削除します。よろしいですか？"}
                open={deleteConfirm}
                onOk={() => {
                    setState({ ...state, photos: state.photos.filter((photo) => { return !photo.checked }) })
                    setDeleteConfirm(false)
                }}
                onCancel={(e) => {
                    setDeleteConfirm(false)
                }}
                onClose={(e) => {
                    setDeleteConfirm(false)
                }}
            />

            <ConfirmationDialog
                dialogTitle="ゴミ箱"
                bodyText={"ゴミ箱から指定された写真データを戻します。よろしいですか？"}
                open={restoreConfirm}
                onOk={() => {
                    const map = new Map()
                    state.photos.filter((photo) => { return photo.checked }).forEach((photo) => {
                        if (!map.has(photo.type)) {
                            map.set(photo.type, new Map())
                        }
                        map.get(photo.type).set(photo.photo.photoId, photo.photo)
                    })
                    map.forEach((photos, key) => {
                        props.connector[key].restoreData(photos)
                    })
                    setState({ ...state, photos: state.photos.filter((photo) => { return !photo.checked }) })
                    setRestoreConfirm(false)
                }}
                onCancel={(e) => {
                    setRestoreConfirm(false)
                }}
                onClose={(e) => {
                    setRestoreConfirm(false)
                }}
            />

            <Card style={{ display: state.window ? "flex" : "none", flexDirection: "column", marginBottom: "0px", overflow: "hidden", backgroundColor: "white", width: "400px", height: "400px" }}
                onDrop={(event) => {
                    inputElement.current.style.backgroundColor = null
                    handleDropPhoto(photoSelect)
                    setPhotoSelect({ type: '', photos: new Map() })
                    event.stopPropagation()
                    return false
                }}
            >
                <CardHeader
                    avatar={
                        <Avatar aria-label="recipe">
                            <DeleteIcon />
                        </Avatar>
                    }
                    title="ゴミ箱"
                />
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                    <Button variant="contained" size="small" color="secondary"
                        onClick={(event) => {
                            setAllDeleteConfirm(true)
                        }}
                    ><ClearAllIcon />空にする</Button>
                    <Button style={{ marginLeft: "5px" }} disabled={!state.photos?.find((photo) => { return photo.checked === true })} variant="contained" size="small" color="secondary"
                        onClick={(event) => {
                            setDeleteConfirm(true)
                        }}
                    ><ClearIcon />選択を削除</Button>
                    <Button style={{ marginLeft: "5px" }} disabled={!state.photos?.find((photo) => { return photo.checked === true })} variant="contained" size="small" color="primary"
                        onClick={(event) => {
                            setRestoreConfirm(true)
                        }}
                    ><RestoreFromTrashIcon />もとに戻す</Button>
                </div>
                <div style={{ display: "flex", marginLeft: "20px" }}>
                    <Checkbox size="small" color="secondary" checked={state.photos?.length !== 0 && !state.photos?.find((photo) => { return photo.checked !== true })} onChange={(event) => {
                        let photos = state.photos.map((photo) => {
                            return { ...photo, checked: event.target.checked }
                        })
                        setState({ ...state, photos: photos })
                    }}
                    />
                    <span style={{ lineHeight: "40px", verticalAlign: "baseline" }}>すべてチェック</span>
                </div>
                <hr style={{ width: "90%" }} />
                <CardContent style={{ position: "relative", margin: "0px", padding: "0px", display: "flex", minHeight: "0px", width: "100%" }}>
                    <List style={{ position: "relative", margin: "0px", padding: "0px", overflow: "auto", width: "100%" }}>
                        {state.photos?.map((photo, idx) => {
                            return (
                                <ListItem key={photo.photo.photoId + "-" + photo.type + "-" + photo.date}>
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <Checkbox size="small" color="secondary" checked={photo.checked} inputProps={{ 'aria-label': 'controlled' }} onChange={(event) => {
                                            let photos = state.photos.slice()
                                            photos[idx] = { ...photos[idx], checked: event.target.checked }
                                            setState({ ...state, photos: photos })
                                        }} />
                                        <Thumbnail key={photo.photo.photoId} maxHeight="32px" size="s" id={"PHOTO-" + idx} index={idx} value={photo.photo} />
                                        <span style={{ verticalAlign: "middle" }}>
                                            <div style={{ display: "inline-block", fontSize: "9px" }}>削除日時：{fmt(photo.date)}</div>
                                            <div style={{ display: "inline-block" }}>撮影日：{formatDate(photo.photo.day)} - {getTypeName(photo.type)}</div>
                                        </span>
                                    </div>
                                </ListItem>
                            )
                        })}
                    </List>
                </CardContent>
            </Card>
            <div style={{ width: "100%", textAlign: "right" }}>
                <IconButton ref={inputElement}
                    onClick={(event) => {
                        setState({ ...state, window: !state.window })
                    }}
                    onDrop={(event) => {
                        inputElement.current.style.backgroundColor = null
                        handleDropPhoto(photoSelect)
                        setPhotoSelect({ type: '', photos: new Map() })
                        event.preventDefault()
                    }}
                    onDragOver={(event) => {
                        inputElement.current.style.backgroundColor = "darkgray"
                        event.preventDefault()
                    }}
                    onDragEnter={(event) => {
                        inputElement.current.style.backgroundColor = "darkgray"
                        event.preventDefault()
                    }}
                    onDragLeave={(event) => {
                        inputElement.current.style.backgroundColor = null
                        event.preventDefault()
                    }}
                >
                    <DeleteIcon
                        onDragOver={(event) => {
                            inputElement.current.style.backgroundColor = "darkgray"
                            event.preventDefault()
                        }}
                    />
                </IconButton>
            </div>
        </div>
    )
}

export default TrashBox