import { MEDIA_SMART_PHONE, MEDIA_PC } from '../utils/Utils'

import React from 'react'
import MediaQuery from 'react-responsive'

export const MediaPc = (props) => {
  return (
    <MediaQuery query={MEDIA_PC}>
      {props.children}
    </MediaQuery>
  )
}

export const MediaSmartPhone = (props) => {
  return (
    <MediaQuery query={MEDIA_SMART_PHONE}>
      {props.children}
    </MediaQuery>
  )
}

export const isTouchDevice = (props) => {
  // 端末がタッチ（タップ）に対応しているか確認する
  var touch_event = window.ontouchstart;
  var touch_points = navigator.maxTouchPoints;

  if (touch_event !== undefined && 0 < touch_points) {
    return true
  } else {
    return false
  }
}