import './ResultManage.css'
import Header from './common/Header'
import ReportListFooter from './result/ReportListFooter'
import Content from './common/Content'
import ConstListController from './result/ConstListController'
import ConstManageChoiser from './result/ConstManageChoiser'

import React, { useState } from 'react'
import { Transition } from 'react-transition-group'

const ResultManage = (props) => {
    const [displayConst, setDisplayConst] = useState(false)
    const [constId, setConstId] = useState("")

    const transitionStyles = {
        entering: { left: '-100%', transition: 'all 300ms ease' },
        entered: { left: '-100%' },
        exiting: { left: '0%', transition: 'all 300ms ease' },
        exited: { left: '0%' },
    }

    return (
        <Content>
            <Header title="工事進捗管理" />
            <Transition in={displayConst} timeout={1500}>
                {(state) => (
                    <div style={{ position: "absolute", display: "block", display: "block", width: "100%", top: "40px", bottom: "55px", ...transitionStyles[state] }}>
                        <ConstListController style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }} constId={constId} onChangeConstId={(constId) => {
                            // 一瞬、選んだ工事にフォーカスが当たったことを見せてから進捗表に遷移する
                            setConstId(constId)
                            setTimeout(() => {
                                setDisplayConst(true)
                            }, 100);
                        }} props={props} />
                        {displayConst ?
                            <ConstManageChoiser style={{ position: "absolute", display: "block", top: 0, left: "100%", right: "-100%", bottom: 0, backgroundColor: "white" }} constId={constId} />
                            : null}
                    </div>
                )}
            </Transition>
            <ReportListFooter backButton={displayConst} onBack={() => setDisplayConst(false)} />
        </Content>
    )
}

export default ResultManage
