import './UserDialog.css'

import { userListData } from '../hooks/UserListData'
import { MediaPc, MediaSmartPhone } from '../common/Responsive'

import React, { useState } from 'react'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Button } from '@mui/material'
import { useRecoilValue } from 'recoil'

const UserDialog = (props) => {
    const userMap = useRecoilValue(userListData) // 案件一覧データ（マップ構造）
    // const [text, setText] = useRecoilState(constIdState)
    // const [open, setOpen] = useState((text === null || text === ''))
    const [filter, setFilter] = useState(null)

    let timeoutId = null
    return (
        <Dialog
            className="UserDialog"
            disableEscapeKeyDown={!props.canCancel}
            maxWidth={"lg"}
            open={props.open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick' && props.onClose) {
                    props.onClose()
                }
            }}
            aria-labelledby="form-dialog-title" >
            <MediaPc>
                <DialogTitle id="form-dialog-title">メンバーを選択して下さい</DialogTitle>
            </MediaPc>
            <DialogContent >
                <div style={{
                    display: "flex", position: "relative", flexDirection: "column"
                }}>
                    <TextField style={{ display: "block" }} onChange={(event) => {
                        if (timeoutId) {
                            clearTimeout(timeoutId)
                        }

                        timeoutId = setTimeout(() => {
                            setFilter(event.target.value)
                        }, 500)
                    }} defaultValue={filter}
                        size="small"
                        label="検索" />
                    <hr style={{ marginTop: "10px", marginBottom: "10px" }} />
                    <table className={"dataList"}>
                        <thead className={"scrollHead"}>
                            <tr>
                                <MediaPc>
                                    <th className={"choise"} style={{ textAlign: "center" }}>選択</th>
                                    <th className={"userId"} style={{}}>アカウント</th>
                                    <th className={"name"} style={{}}>氏名</th>
                                    <th className={"groupName"} style={{ textAlign: "center" }}>グループ</th>
                                    <th className={"mail"} style={{ textAlign: "center" }}>メールアドレス</th>
                                </MediaPc>
                                <MediaSmartPhone>
                                    <th colSpan={2} style={{ textAlign: "center" }}>選択</th>
                                </MediaSmartPhone>
                            </tr>
                        </thead>
                        <tbody className={"scrollBody"} >
                            {
                                Array.from(userMap.values()).filter(row => {
                                    return (!filter
                                        || (row.userId && row.userId.indexOf(filter, 0) >= 0)
                                        || (row.name && row.name.indexOf(filter, 0) >= 0)
                                        || (row.groupName && row.groupName.indexOf(filter, 0) >= 0)
                                        || (row.email && row.email.indexOf(filter, 0) >= 0)
                                    )
                                }).map(row => (
                                    <React.Fragment>
                                        <MediaPc>
                                            <tr key={row.userId}>
                                                <td className={"choise"}>
                                                    <Button variant="contained" size="small"
                                                        onClick={() => {
                                                            if (props.onChoise) {
                                                                props.onChoise(row)
                                                            }
                                                        }} color="primary">
                                                        選択
                                                    </Button>
                                                </td>
                                                <td className={"userId"}>
                                                    <div>{row.userId}</div>
                                                </td>
                                                <td className={"name"}>
                                                    <div>{row.name}</div>
                                                </td>
                                                <td className={"groupName"}>
                                                    <div>{row.groupName}</div>
                                                </td>
                                                <td className={"email"}>
                                                    <div>{row.email}</div>
                                                </td>
                                            </tr>
                                        </MediaPc>
                                        <MediaSmartPhone>
                                            <tr key={row.userId} onClick={() => {
                                                if (props.onChoise) {
                                                    props.onChoise(row)
                                                }
                                            }} >
                                                <td className={"name"}>
                                                    <div>{row.name}</div>
                                                </td>
                                                <td className={"groupName"}>
                                                    <div>{row.groupName}</div>
                                                </td>
                                            </tr>
                                        </MediaSmartPhone>
                                    </React.Fragment>
                                ))}
                        </tbody>
                    </table>
                </div>
            </DialogContent>
            {
                (props.canCancel) ? (
                    <DialogActions>
                        <Button
                            variant="contained"
                            onClick={() => {
                                if (props.onClose) {
                                    props.onClose()
                                }
                            }} >
                            キャンセル
                        </Button>
                    </DialogActions>
                ) : (
                    <React.Fragment />
                )
            }
        </Dialog >
    )

}

export default UserDialog