import './CellInput.css'

import { formatDate } from '../utils/Utils'
import React, { useState, useEffect } from 'react'
import { TextareaAutosize } from '@mui/material'

const CellInput = (props) => {
    const [errorMessage, setErrorMessage] = useState(props.error)

    useEffect(() => {
        setErrorMessage(props.error)
    }, [props.error])

    if (props.readOnly) {
        let value = cvt(props.defaultValue ? props.defaultValue : props.value, props.type)
        return (
            <pre className="CellInput" {...props} style={{
                ...props.style,
                backgroundColor: "white",
                overflow: "hidden",
                overflowWrap: "break-all",
                whiteSpace: props.line === 1 ? "no-wrap" : "pre-wrap",
                fontSize: "13.3333px",
                fontFamily: "Arial",
                color: "#222",
                paddingTop: "2px",
                paddingBottom: "1px"
            }} title={props.title ? props.title : value}

            >
                {value ? value : '\u00A0'}
            </ pre>
        )
    }
    if (parseInt(props.line, 10) > 1) {
        return (
            <div style={{ position: "relative", width: "inherit" }}>
                <TextareaAutosize className="CellInput" readOnly={props.readOnly} minRows={props.line} onKeyDownCapture={() => {
                    setErrorMessage('')
                }} {...props}>
                    {props.value}
                </TextareaAutosize>
                <div style={{ position: "relative", display: errorMessage ? "block" : "none", whiteSpace: "nowrap" }}><ErrorDisplay message={errorMessage} /></div>
            </div>
        )
    } else {
        return (
            <div style={{ position: "relative", width: "inherit" }}>
                <input type="text" ref={props?.inputRef} className="CellInput" readOnly={props.readOnly} title={props.title ? props.title : props.value}
                    maxlength={props.maxlength}
                    onChangeCapture={() => {
                        setErrorMessage('')
                    }}
                    {...props} />
                <div style={{ position: "relative", display: errorMessage ? "block" : "none", whiteSpace: "nowrap" }}><ErrorDisplay message={errorMessage} /></div>
            </div>
        )
    }
}

const cvt = (value, type) => {
    if (value && type === "date") {
        value = formatDate(new Date(value))
    }
    if (value === "") {
        value = "　"
    }
    return value
}

const ErrorDisplay = (props) => {
    return (
        <div style={{ left: "10px", backgroundColor: "pink", border: "1px solid #ccc", zIndex: 1, position: "absolute", color: "red", fontWeight: "bold" }}>{props.message}</div>
    )
}

export default CellInput
