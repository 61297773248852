import './SystemSetting.css'

import Content from './common/Content'
import Header from './common/Header'
import Footer from './common/Footer'
import MainContent from './common/MainContent'

import Role, { RoleSaveButton } from './setting/Role'
import User, { UserSaveButton } from './setting/User'

import { userListData } from './hooks/UserListData'

import { errorState } from './hooks/ErrorState'

import React, { useState } from 'react'
import { useSetRecoilState } from 'recoil'
import { Tabs, Tab } from '@mui/material'

const SystemSetting = (props) => {
    const [tabIndex, setTabIndex] = useState(0)

    let connector = { groups: () => { }, roles: () => { }, }

    // useEffect(() => {
    //     Promise.all(
    //         [
    //             UserService.findAll(null, null, [])
    //         ]
    //     ).then(
    //         response => {
    //             let map = new Map()
    //             response[0].data.objects.forEach((v) => {
    //                 map.set(v.userId, v)
    //             })
    //             // state更新
    //             setUserList(map);
    //         },
    //         error => {
    //             setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
    //         }
    //     ).catch(error => {
    //         setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
    //     })
    // }, [])
    const contents = [
        // { title: "ユーザー", content: (<User connector={connector} />), footer: (<UserSaveButton connector={connector} />) },
        { title: "権限", content: (<Role connector={connector} />), footer: (<RoleSaveButton connector={connector} />) },
    ]
    return (
        <Content>
            <Header title="システム設定" />
            <MainContent className="SystemSetting" style={{ overflow: "hidden" }}>
                <div style={{ margin: "10px", position: "relative", height: "100%", width: "calc(100% - 20px)" }}>
                    <Tabs
                        style={{ position: "relative", }}
                        indicatorColor="primary"
                        textColor="primary"
                        selectionFollowsFocus={true}
                        value={tabIndex}
                        onChange={(event, v) => {
                            setTabIndex(v)
                        }}
                    >
                        {contents.map((row) => {
                            return (<Tab label={row.title} style={{ backgroundColor: "white", }} />)
                        })}

                    </Tabs>
                    <div style={{ position: "relative", height: "calc(100% - 70px)", width: "100%" }}>
                        {contents[tabIndex].content}
                    </div>
                </div>
            </MainContent>
            <Footer>
                {contents[tabIndex].footer}
            </Footer>
        </Content>
    )
}

export default SystemSetting