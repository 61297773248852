import './NumberInput.css'

import React, { useState, useEffect } from 'react'

const NumberInput = (props) => {
  const [value, setValue] = useState(props.value)
  const addSeparator = (v) => {
    if (v === undefined || v === null || v === "") {
      return ''
    }

    return getValue(v).toLocaleString("ja-JP")
  }

  const removeSeparator = (v) => {
    if (v === undefined || v === null || v === "") {
      return ''
    }
    if (v === '-') {
      return '-'
    }
    v = '' + v
    let r = v.replace(/[^0-9]*/g, '')
    if (v === undefined || v === "") {
      return "0"
    }
    return r * (v.slice(0, 1) === '-' ? -1 : 1)
  }
  const getValue = (v, d = null) => {
    let r = 0

    if (v === undefined || v === null || v === "") {
      return d
    }

    let s = removeSeparator(v)

    if (s === '-') {
      return '-'
    }
    if (Number(s)) {
      r = Number(s)
    }
    return r
  }

  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  let timeoutId
  return (
    <input type="tel" className={"NumberInput " + props.className}
      value={addSeparator(value)}
      maxlength={props.maxlength ? props.maxlength : 15}
      readOnly={props.readOnly}
      style={props.style}
      onChange={(event) => {
        setValue(event.target.value)
        if (props.onChange) {
          // キー入力のリピートに対応する為、onChangeを遅延実行させる
          let e = { ...event }
          if (timeoutId) {
            clearTimeout(timeoutId)
          }
          timeoutId = setTimeout(() => {
            props.onChange(e)
          }, 100)
        }
      }}
      onBlur={(event) => {
        if (props.onChangeValue && props.value !== value) {
          props.onChangeValue(getValue(value))
        }
      }}
    />
  )
}

export default NumberInput