import './Header.css'

import { displayMenuState } from '../hooks/DisplayMenuState'
import { sessionUserState } from '../hooks/SessionUserState'
import ConfirmationDialog from './ConfirmationDialog'
import { errorState } from '../hooks/ErrorState'
import Setting from './Setting'

import { displaySettingState } from '../hooks/DisplaySettingState'
import LoginService from '../../services/LoginService'

import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu'
import HomeIcon from '@mui/icons-material/Home'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import SettingsIcon from '@mui/icons-material/Settings'

import { IconButton } from '@mui/material'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

const Header = (props) => {
    const [menuState, setMenuState] = useRecoilState(displayMenuState)
    const [settingPanel, setSettingPanel] = useRecoilState(displaySettingState)
    const [logoutDialog, setLogoutDialog] = useState(false)
    const [userData, setUserData] = useRecoilState(sessionUserState)
    const setError = useSetRecoilState(errorState) // エラーメッセージ表示用
    const navigate = useNavigate()

    const logout = () => {
        LoginService.logout()
            .then(
                response => {
                    setLogoutDialog(false)
                    setUserData({
                        key: 'sessionUser',
                        default: {
                            email: "",
                            firstName: "",
                            groupId: "",
                            groupName: "",
                            lastName: "",
                            name: "",
                            roles: [],
                            userId: "",
                            userName: "",
                            superiorId: "",
                            superiorName: "",
                        },
                    })
                    window.location.href = '/'
                },
                error => {
                    setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
                }
            ).catch(error => {
                setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
            })
    }

    return (
        <div>
            <ConfirmationDialog
                dialogTitle="ログアウト"
                bodyText={"ログアウトします。よろしいですか？"}
                open={logoutDialog}
                onOk={() => {
                    logout()
                }}
                onCancel={(e) => {
                    setLogoutDialog(false)
                }}
                onClose={(e) => {
                    setLogoutDialog(false)
                }}
            />

            <div className="Header">
                <div className="Title">{props.title}</div>
                <IconButton className="MenuIcon"
                    style={{ visibility: userData.personalSetting ? "visible" : "hidden" }}
                    onClick={(e) => {
                        // メニューボタンでメニューを表示
                        setMenuState(!menuState)

                        // Contentコンポーネントへのイベント伝搬を抑制
                        e.stopPropagation()
                    }}
                >
                    <MenuIcon />
                </IconButton>
                <IconButton className="HomeIcon"
                    style={{ visibility: userData.personalSetting ? "visible" : "hidden" }}
                    onClick={(e) => {
                        navigate('/')
                    }}
                >
                    <HomeIcon style={{ color: "white", display: "inline-block" }} />

                </IconButton>
                <div className="Info">
                    <div id="ProgressIndicator">
                        <img style={{ display: "block" }} height="100%" width="100%" src="images/loading.gif" alt="ロード中"></img>
                    </div>

                    <IconButton className="SettingsIcon"
                        onClick={(e) => {
                            setSettingPanel(!settingPanel)

                            // Contentコンポーネントへのイベント伝搬を抑制
                            e.stopPropagation()
                        }}
                    >
                        <SettingsIcon style={{ color: "white", display: "inline-block" }} />

                    </IconButton>
                    <div className="UserName">{userData.name}</div>
                    <IconButton className="ExitToAppIcon"
                        onClick={(e) => {
                            setLogoutDialog(true)

                            // Contentコンポーネントへのイベント伝搬を抑制
                            e.stopPropagation()
                        }}
                    >
                        <ExitToAppIcon style={{ color: "white", display: "inline-block" }} />
                    </IconButton>
                </div>
            </div>
            <Setting />
        </div >
    )
}


export default Header