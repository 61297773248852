import './UserChoiser.css'

import UserDialog from './UserDialog'
import CellInput from './CellInput'
import UserName from './UserName'
import { IconButton } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

import { userListData } from '../hooks/UserListData'

import React, { useState, useEffect, useRef } from 'react'

import { useRecoilValue } from 'recoil'

const UserChoiser = (props) => {
    const userMap = useRecoilValue(userListData) // 社員一覧データ（マップ構造）
    const [userDialogOpen, setUserDialogOpen] = useState(false)  // 案件選択ダイアログの表示コントロール
    const [userId, setUserId] = useState(props.value)
    const [backgroundColor, setBackgroundColor] = useState(null)
    const [errorMessage, setErrorMessage] = useState(props.error)
    const inputEl = useRef(null)

    const dataList = Array.from(userMap.values()).map((u) => {
        return (<option value={u.name} />)
        // return (<option key={u.userId} value={u.name}>{u.name}</option>)
    })
    // dataList.unshift({ label: ' 　', key: '-', o: {} })

    const handleChoice = (value, blur) => {
        let user = null
        let col = null
        if (value === '') {
            setUserId('')
            props.onChoiseUser('')
            return
        }

        let users = userMap.values()
        let u = users.next()
        while (!u.done) {
            if (u.value.name == value) {
                user = u.value
            }
            u = users.next()
        }
        if (user) {
            if (props.onChoiseUser) {
                setUserId(user.userId)
                props.onChoiseUser(user.userId)
            }
        } else {
            // setUserId(user.userId)
            props.onChoiseUser('')
            if (blur) {
                col = "#f88"
            }
        }
        setBackgroundColor(col)
    }

    useEffect(() => {
        setErrorMessage(props.error)
    }, [props.error])

    useEffect(() => {
        setUserId(props.value)
    }, [props.value])

    return (
        <div className="UserChoiser" {...props}>
            <UserDialog open={userDialogOpen}
                canCancel={true}
                onChoise={(user) => {
                    if (props.onChoiseUser) {
                        inputEl.current.value = user.name
                        setUserId(user.userId)
                        props.onChoiseUser(user.userId)
                    }
                    setErrorMessage('')
                    setBackgroundColor(null)
                    setUserDialogOpen(false)
                }}
                onClose={() => {
                    setUserDialogOpen(false)
                }}
            />
            <div className={"name"} >
                {props.readOnly ? (
                    <UserName userId={props.value} />
                ) : (
                    <React.Fragment>
                        <CellInput inputRef={inputEl} placeholder="ユーザー名を選択"
                            style={{ backgroundColor: backgroundColor }}
                            defaultValue={userMap.get(userId) ? userMap.get(userId).name : ''}
                            list="user-choiser-list"
                            onBlur={(event) => {
                                handleChoice(event.target.value, true)
                            }}
                            onInput={(event) => {
                                handleChoice(event.target.value, false)
                            }}
                            error={errorMessage}
                        />
                        <datalist id="user-choiser-list">
                            {dataList}
                        </datalist>
                    </React.Fragment>

                    // <DataListInput
                    //     placeholder="ユーザー名を選択"
                    //     value={userMap.get(props.value) ? userMap.get(props.value).name : ''}
                    //     items={dataList}
                    //     onSelect={(event) => {
                    //         if (props.onChoiseUser) {
                    //             props.onChoiseUser(event.key)
                    //         }
                    //     }}
                    // />
                )}
            </div>
            {!props.readOnly ?
                (<IconButton size="small"
                    aria-label="search" color="primary"
                    onClick={() => {
                        setUserDialogOpen(true)
                    }}>
                    <SearchIcon style={{ fontSize: 14 }} />
                </IconButton>) : (
                    <React.Fragment />
                )
            }
        </div>
    )
}

export default UserChoiser
