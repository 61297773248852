import { MediaPc } from '../common/Responsive'

import Footer from '../common/Footer'
import { resultTabIndex } from '../hooks/ResultTabIndex'
import { resultSaveState } from '../hooks/ResultSaveState'
import { resultOutputExcelState } from '../hooks/ResultOutputExcelState'
import { resultImportCsvState } from '../hooks/ResultImportCsvState'
import { resultExportCsvState } from '../hooks/ResultExportCsvState'

import { Box, Button } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import FileDownloadIcon from '@mui/icons-material/FileDownload'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFileExcel } from '@fortawesome/free-solid-svg-icons'

import { useRecoilValue, useSetRecoilState } from 'recoil'

import React from 'react'

const ReportListFooter = (props) => {
  const tabIndex = useRecoilValue(resultTabIndex) // タブの切り替え
  const setSave = useSetRecoilState(resultSaveState)
  const setOutputExcel = useSetRecoilState(resultOutputExcelState)
  const setImportCsv = useSetRecoilState(resultImportCsvState)
  const setExportCsv = useSetRecoilState(resultExportCsvState)

  const buttons = props.backButton ?
    <React.Fragment>
      <Box style={{ paddingRight: "10px" }}>
        <Button color="inherit" variant="contained" onClick={(event) => {
          props.onBack()
        }}
        ><ArrowBackIcon /><MediaPc>戻る</MediaPc></Button>
      </Box>
      <Box style={{ paddingRight: "10px" }}>
        <Button variant="contained" color="secondary" onClick={(event) => {
          setSave(true)
        }}><SaveAltIcon /><MediaPc>保存</MediaPc></Button>
      </Box>
      <Box style={{ paddingRight: "10px" }}>
        <Button variant="contained" color="primary" onClick={(event) => {
          setOutputExcel(true)
        }}><FontAwesomeIcon icon={faFileExcel} size="lg" /><MediaPc>&nbsp;Ｅｘｃｅｌ出力</MediaPc></Button>
      </Box>
      <Box style={{ paddingRight: "10px" }}>
        <Button color="inherit" variant="contained" onClick={(event) => {
          setImportCsv(true)
        }}
        ><FileUploadIcon /><MediaPc>インポート</MediaPc></Button>
      </Box>
      <Box style={{ paddingRight: "10px" }}>
        <Button color="inherit" variant="contained" onClick={(event) => {
          setExportCsv(true)
        }}
        ><FileDownloadIcon /><MediaPc>エクスポート</MediaPc></Button>
      </Box>
    </React.Fragment>
    : <React.Fragment />

  return (
    <Footer>
      {buttons}
    </Footer>
  )
}

export default ReportListFooter