import React from 'react'

const DateTime = (props) => {
    let v = props.value
    if (!v) {
        return ''
    }
    let dt = new Date(v)
    let y = dt.getFullYear()
    let m = ("00" + (dt.getMonth() + 1)).slice(-2)
    let d = ("00" + dt.getDate()).slice(-2)
    let h = ("00" + dt.getHours()).slice(-2)
    let M = ("00" + dt.getMinutes()).slice(-2)
    let idx = dt.getDay()
    let w = '日月火水木金土'[idx]
    return (
        <span>
            {y + "/" + m + "/" + d}
            (<span style={(idx === 0 || idx === 6) ? { color: "#FF3F3F" } : {}}>{w}</span>)
            &nbsp;{h + ":" + M}
        </span>
    )
}

export default DateTime