import React, { useRef } from 'react'
import { Button } from '@mui/material'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

const CommentDialog = (props) => {
    const elm = useRef(null)    // コメント入力フィールドの参照を保持

    return (
        <Dialog
            open={props.open} onClose={(e) => {
                if (props.onClose) {
                    props.onClose(e)
                }
            }} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{props.dialogTitle ? props.dialogTitle : "コメントを入力"}</DialogTitle>
            <DialogContent style={{ overflow: 'hidden' }}>
                {props.bodyText}
                <TextField
                    inputRef={elm}
                    autoFocus
                    multiline
                    rows={5}
                    margin="dense"
                    id="comment"
                    label="コメント"
                    type="text"
                    fullWidth
                    variant="outlined"
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" size="small"
                    onClick={(e) => {
                        if (props.onClose) {
                            props.onClose(e)
                        }
                    }}>
                    キャンセル
                </Button>
                <Button variant="contained" size="small"
                    onClick={() => {
                        if (props.onComment) {
                            const value = elm.current.value
                            props.onComment(value)
                        }
                    }} color="primary">
                    決定
                </Button>
            </DialogActions>
        </Dialog>
    )

}

export default CommentDialog