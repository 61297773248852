import './ConstReportComments.css'

import AlreadyReads from '../common/AlreadyReads'
import UserName from '../common/UserName'

import { userListData } from '../hooks/UserListData'
import { sessionUserState } from '../hooks/SessionUserState'
import { GreenIconButton } from '../common/ColorButton'

import ConstDiscussionService from '../../services/ConstDiscussionService'
import React, { useEffect, useState } from 'react'
import { Badge, TextareaAutosize } from '@mui/material'
import DoneIcon from '@mui/icons-material/Done'
import { useRecoilValue } from 'recoil'

const ConstReportComments = (props) => {
    const [text, setText] = useState('')
    const [constReportDiscussion, setConstReportDiscussion] = useState(props.initialValue)
    const userData = useRecoilValue(sessionUserState)

    useEffect(() => {
        setConstReportDiscussion(props.initialValue)
    }, [props.initialValue])

    const addDiscussion = (id, text) => {
        ConstDiscussionService.addDiscussion(id, encodeURIComponent(text))
            .then(
                response => {
                    return ConstDiscussionService.loadDiscussion(id)
                },
                error => {
                    props.onError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
                }
            ).then(
                response => {
                    setConstReportDiscussion(response.data)
                    setText('')
                }
            ).catch(error => {
                props.onError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
            })
    }

    return (
        <div className="ConstReportComments" style={{ backgroundColor: "lightgray" }}>
            <div >コメント</div>
            <div style={{ margin: "4px" }}>
                <div style={{ border: 0, backgroundColor: "#ddd" }}>
                    {
                        constReportDiscussion.discussion.map((r) => {
                            let readCheck = r.readCheck.find((row) => { return (row.userId === userData.userId && row.check === false) })
                            return (
                                <div div style={{ marginBottom: "10px", padding: "10px 5px" }}>
                                    {(r.userId === userData.userId && !props.readOnly) ?
                                        (
                                            <MyComment value={r} />
                                        ) : (
                                            <OtherComment value={r} readCheck={readCheck} />
                                        )}
                                </div>
                            )
                        })
                    }
                </div>
                {!props.readOnly ? (
                    <div style={{ marginTop: "10px", display: "block", height: "auto" }}>
                        <TextareaAutosize
                            maxRows={64}
                            minRows={5}
                            value={text}
                            style={{ border: "none", outline: "none", width: "calc(100% - 10px)", margin: "2px" }}
                            onInput={(e) => {
                                setText(e.target.value)
                            }}
                        />
                        <GreenIconButton size="small"
                            className="SendButton"
                            style={{ position: "absolute", right: "30px", bottom: "18px", height: "auto" }}
                            ariaLabel="edit"
                            disabled={!text}
                            color="primary"
                            onClick={(e) => {
                                addDiscussion(constReportDiscussion._id, text)
                            }}>
                            <DoneIcon style={{ color: "white" }} />
                        </GreenIconButton>
                    </div>

                ) : (
                    <React.Fragment />
                )}
            </div >
        </div >
    )
}

const formatDateTime = (dt) => {
    if (!dt) {
        return dt
    }
    if (typeof (dt) === 'string') {
        dt = new Date(dt)
    }
    let y = dt.getFullYear()
    let m = ("00" + (dt.getMonth() + 1)).slice(-2)
    let d = ("00" + dt.getDate()).slice(-2)
    let h = ("00" + dt.getHours()).slice(-2)
    let M = ("00" + dt.getMinutes()).slice(-2)
    // let s = ("00" + dt.getSeconds()).slice(-2)
    let idx = dt.getDay()
    let w = '日月火水木金土'[idx]
    return (
        <span>
            {y + "/" + m + "/" + d}
            (<span style={(idx === 0 || idx === 6) ? { color: "#FF3F3F" } : {}}>{w}</span>)
            {h + ":" + M}
        </span>
    )
}

const MyComment = (props) => {
    const userData = useRecoilValue(sessionUserState)

    let r = props.value
    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-end", marginRight: "25px" }}>
                <div style={{ fontSize: "7px", color: "darkgray" }} >
                    <AlreadyReads value={r.readCheck} myself={userData.userId} otherComment={false} />
                    <div >{formatDateTime(r.date)}</div>
                </div>
                <Badge style={{ backgroundColor: "lightgreen", margin: "5px", maxWidth: "80%", width: "auto" }}>
                    <div style={{ fontSize: "14px", margin: "3px" }} >
                        <pre className="message" >{r.message}</pre>
                    </div>
                </Badge>
            </div>
        </div>
    )
}

const OtherComment = (props) => {
    const userMap = useRecoilValue(userListData) // 案件一覧データ（マップ構造）
    const userData = useRecoilValue(sessionUserState)

    let r = props.value
    return (
        <div>
            <div style={{ fontSize: "14px", marginLeft: "15px" }} ><UserName userId={r.userId} /></div>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-end", marginLeft: "25px" }}>
                <Badge style={{ backgroundColor: "white", maxWidth: "80%", width: "auto" }} variant="dot" color="secondary" invisible={!props.readCheck} anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}>
                    <div style={{ fontSize: "14px", margin: "3px" }} >
                        <pre className="message" >{r.message}</pre>
                    </div>
                </Badge>
                <div style={{ fontSize: "7px", color: "darkgray" }} >
                    <AlreadyReads value={r.readCheck} myself={userData.userId} otherComment={true} />
                    <div >{formatDateTime(r.date)}</div>
                </div>
            </div>
        </div>
    )
}

export default ConstReportComments
