import './ConstReportDiscussion.css'
import Header from './common/Header'
import Footer from './common/Footer'
import Content from './common/Content'
import MainContent from './common/MainContent'
import InfoBox from './common/InfoBox'
import CellInput from './common/CellInput'
import DateTime from './common/DateTime'
import ConstSelector from './common/ConstSelector'
import ConstReport from './report/ConstReport'
import ConstReportComments from './report/ConstReportComments'
import { MediaSmartPhone } from './common/Responsive'

import ConstDiscussionService from '../services/ConstDiscussionService'
import ConstReportService from '../services/ConstReportService'
import CameraService from '../services/CameraService'
import { constListData } from './hooks/ConstListData'
import { formatDate, getInitialConstReport, convMongoIsoDate } from './utils/Utils'
import { errorState } from './hooks/ErrorState'

import React, { useState, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { atom, useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil'
import queryString from 'query-string'

import { IconButton, Card, Paper, Button } from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'

const fmt = (dt) => {
    let y = dt.getFullYear()
    let m = ("00" + (dt.getMonth() + 1)).slice(-2)
    let d = ("00" + dt.getDate()).slice(-2)
    return `${y}-${m}-${d}`
}

const dateAdd = (dt, day) => {
    dt.setDate(dt.getDate() + day)
    return dt
}

const searchConditionState = atom({
    key: 'constDiscussion.searchCondition',
    default: { constId: '', start: fmt(dateAdd(new Date(), -2)), end: fmt(new Date()) },
})

const ConstReportDiscussion = (props) => {
    const [constDiscussions, setConstDiscussions] = useState([])
    const setError = useSetRecoilState(errorState) // エラーメッセージ表示用
    const [page, setPage] = useState("today")

    const constList = useRecoilValue(constListData) // 案件一覧データ（マップ構造）
    const [condition, setCondition] = useRecoilState(searchConditionState)
    const [query, setQuery] = useState({ constId: null, startDate: null, endDate: null })

    const search = useLocation().search
    const queryParameter = queryString.parse(search)

    if (query?.constId !== queryParameter.constId
        || query?.startDate !== queryParameter.startDate
        || query?.endDate !== queryParameter.endDate) {
        setQuery(queryParameter)
    }

    useEffect(() => {
        setCondition({
            constId: query.constId ? query.constId : '',
            start: query.startDate ? fmt(new Date(query.startDate)) : fmt(dateAdd(new Date(), -2)),
            end: query.endDate ? fmt(new Date(query.endDate)) : fmt(new Date())
        })
    }, [query])

    useEffect(() => {
        loadConstDiscussion(condition)
    }, [condition]);

    const loadConstDiscussion = (key) => {
        setConstDiscussions([])
        ConstDiscussionService.load(key)
            .then(
                response => {
                    let constDiscussionDatas = response.data.objects

                    setConstDiscussions(constDiscussionDatas)
                },
                error => {
                    setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
                }
            ).catch(error => {
                setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
            })
    }

    return (
        <Content>
            <Header title="工事日報確認" />
            <MainContent className="ConstReportDiscussion">
                <SearchCondition value={condition} onChangeSearchCondition={(condition) => {
                    setCondition(condition)
                }} />
                <div className="list">
                    {constDiscussions.map((r) => {
                        return (
                            <div>
                                {r.constReportId ? (
                                    <ConstReportRow key={r._id} page={page} value={r} onError={(e) => {
                                        setError(e)
                                    }} />
                                ) : (
                                    <div style={{ position: "relative", margin: "10px", display: "flex", flexDirection: "row" }}>
                                        <div style={{ width: "100px", backgroundColor: "white" }} />
                                        <div className="outlinePanel" variant="outlined">
                                            <div style={{ width: "100%", height: "130px", padding: "5px" }}>
                                                <InfoBox title="工事">{r.constId}&nbsp;{constList.get(r.constId)?.name}</InfoBox>
                                                <InfoBox title="日付">{formatDate(r.date)}</InfoBox>
                                                {r.statusMessage ? (
                                                    <div style={{ position: "absolute", display: "flex", justifyContent: "center", alignItems: "center", top: "50px", bottom: 0, left: 0, right: 0, fontSize: "30px", margin: "auto", backgroundColor: "rgba(255,255,255,0.5)" }}>
                                                        <div style={{ color: "darkblue" }}>
                                                            {r.statusMessage}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <React.Fragment />
                                                )}
                                            </div>
                                        </div>
                                    </div >
                                )}
                                <hr style={{ border: "2px solid #0000", margin: "0px", width: "calc(100% - 20px)" }} />
                            </div>
                        )
                    })}
                </div>
            </MainContent>
            <Footer>
                <MediaSmartPhone>
                    <PrevArrow page={page} onClick={() => {
                        setPage("today")
                    }} />

                    <NextArrow page={page} onClick={() => {
                        setPage("next")
                    }} />
                </MediaSmartPhone>
            </Footer>
        </Content>
    )
}

const SearchCondition = (props) => {
    const [searchCondition, setSearchCondition] = useState(props.value)

    useEffect(() => {
        setSearchCondition(props.value)
    }, [props.value])

    return (
        < div style={{ backgroundColor: "white", padding: "5px" }}>
            <InfoBox title={"日付範囲"}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <CellInput key={"start"} type="date" value={searchCondition.start}
                        onChange={(event) => {
                            setSearchCondition({ ...searchCondition, start: event.target.value })
                        }}
                    />
                    <span style={{ verticalAlign: "middle" }}>〜</span>
                    <CellInput key={"end"} type="date" value={searchCondition.end}
                        onChange={(event) => {
                            setSearchCondition({ ...searchCondition, end: event.target.value })
                        }}
                    />
                </div>
            </InfoBox>
            <InfoBox title={"工事番号"} style={{ width: "600px" }}>
                <ConstSelector value={searchCondition.constId}
                    onChangeConstId={(constId) => {
                        setSearchCondition({ ...searchCondition, constId: constId })
                    }} />
            </InfoBox>
            <Button
                style={{ marginLeft: "10px" }}
                variant="contained"
                color="inherit"
                onClick={() => {
                    props.onChangeSearchCondition(searchCondition)
                }} >
                検索
            </Button>
        </div >
    )
}

const ConstReportRow = (props) => {
    let [constReport, setConstReport] = useState({ today: {}, next: {} })

    const buildDisplayConstReport = (data) => {
        let today = new Date(data.today.date)
        let next = new Date(data.next.date)
        let d = {
            id: data.today._id,
            constId: data.today.constId,
            accountId: data.today.accountId,
            sendDate: '',
            updateDates: [],
            superiorId: '',    // TODO:ロードできない場合、デフォルトの上長をセットする必要有り
            members: [],
            dates: { today: today, next: next },
            today: getInitialConstReport(today),
            next: getInitialConstReport(next),
        }

        if (data.today) {
            d.sendDate = data.today.sendDate
            d.updateDates = data.today.updateDates
            d.superiorId = data.today.superiorId
            d.today = { ...data.today, date: today }
            d.members = data.today.members
        }

        if (data.next) {
            d.next = { ...data.next, date: next }
        }
        return d
    }

    const loadConstReport = (id) => {
        let constReportData = {}
        ConstReportService.loadById(id)
            .then(
                response => {
                    constReportData = buildDisplayConstReport(response.data)
                    return CameraService.getPhotos(`{ constId: "${constReportData.constId}", day: ${convMongoIsoDate(constReportData.dates.today)}, imagePath: {$exists: true} }`)
                },
                error => {
                    props.onError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
                }
            ).then(
                response => {
                    constReportData.photos = response.data.objects

                    setConstReport(constReportData)
                },
                error => {
                    props.onError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
                }
            ).catch(error => {
                props.onError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
            })
    }

    useEffect(() => {
        loadConstReport(props.value.constReportId)
    }, [])

    // const constReportMemo = useMemo(() => { return (<ConstReport value={constReport} readOnly={true} discussion={true} page={props.page} />) }, [constReport])

    return (
        <div key={props.key} style={{ position: "relative", display: "flex", flexDirection: "row" }}>
            <Card className="outlinePanel" variant="outlined">
                <Paper className="paper">
                    <div className="submitDate">
                        <InfoBox title="提出日時">
                            <div><DateTime value={constReport.sendDate} /></div>
                            {constReport.updateDates?.map((r) => {
                                return (<div>再提出：<DateTime value={r} /></div>)
                            })}
                        </InfoBox>
                    </div>
                    <div style={{ position: "relative" }}>
                        {/* {constReportMemo} */}
                        <ConstReport value={constReport} readOnly={true} discussion={true} page={props.page} />
                        <Pager />
                    </div>
                    <ConstReportComments initialValue={props.value} onError={(error) => {
                        props.onError(error)
                    }} />
                </Paper>
            </Card>
        </div >
    )
}

const Pager = (props) => {
    return (
        <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, pointerEvents: "none" }} />
    )
}

const NextArrow = (props) => {
    return (
        <IconButton className={" Pager"} style={{ position: "absolute", display: (props.page === "today" ? "block" : "none"), width: "40px", top: "0px", right: "5px", color: "white" }} aria-label="edit"
            onClick={props.onClick}
        >
            <ArrowForwardIosIcon />
        </IconButton>
    )
}

const PrevArrow = (props) => {
    return (
        <IconButton className={" Pager"} style={{ position: "absolute", display: (props.page === "next" ? "block" : "none"), width: "40px", top: "0px", left: "5px", color: "white" }} aria-label="edit"
            onClick={props.onClick}
        >
            <ArrowBackIosIcon />
        </IconButton>
    )
}

export default ConstReportDiscussion
