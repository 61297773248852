import { http } from "./Config";

const requestPath = "/v1/system";

export const getTaxs = () => {
    return http.get(`${requestPath}/tax`)
}

const exports = {
    getTaxs,
}

export default exports;
