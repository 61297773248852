import CellInput from '../common/CellInput'
import UserChoiser from '../common/UserChoiser'
import PrintQuolityChoiser from '../common/PrintQuolityChoiser'
import InfoBox from '../common/InfoBox'

import React, { useState, useEffect, useRef, } from 'react'

import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, FormControlLabel, Checkbox } from '@mui/material'

const PrintSetting = (props) => {
  const [printSetting, setPrintSetting] = useState(props.value)

  const fmt = (dt) => {
    let y = dt.getFullYear()
    let m = ("00" + (dt.getMonth() + 1)).slice(-2)
    let d = ("00" + dt.getDate()).slice(-2)
    return `${y}-${m}-${d}`
  }

  useEffect(() => {
    setPrintSetting(props.value)
  }, [props.value])

  return (
    <Dialog
      open={props.open} onClose={(e) => {
        props.onClose(e)
      }} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{props.dialogTitle ? props.dialogTitle : "PDF設定"}</DialogTitle>
      <DialogContent style={{ overflow: 'hidden' }}>
        <InfoBox title="詳細出力">
          <FormControlLabel
            style={{ marginTop: "-5px" }}
            control={
              <Checkbox size="small" color="secondary" onChange={(event) => {
                setPrintSetting({ ...printSetting, printDetail: event.target.checked })
              }} checked={printSetting.printDetail} />
            }
            label="詳細出力有り"
          />
        </InfoBox>
        <InfoBox title="担当者">
          <UserChoiser value={printSetting.userId} onChoiseUser={(userId) => {
            setPrintSetting({ ...printSetting, userId: userId })
          }} />
        </InfoBox>
        <InfoBox title="開始ページ">
          <CellInput type="number" style={{ textAlign: "right" }} value={printSetting.startPage} onInput={(event) => {
            setPrintSetting({ ...printSetting, startPage: event.target.value })
          }} />
        </InfoBox>
        <InfoBox title="日付">
          <CellInput type="date" value={fmt(new Date(printSetting.date))} onInput={(event) => {
            setPrintSetting({ ...printSetting, date: new Date(event.target.value) })
          }}
          />
        </InfoBox>
        <InfoBox title="写真品質">
          <PrintQuolityChoiser value={printSetting.printQuolity} onChange={(event) => {
            setPrintSetting({ ...printSetting, printQuolity: event.target.value })
          }} />
        </InfoBox>
        <Box style={{ marginTop: "5px", padding: "5px 5px 10px 5px", border: "1px solid lightgray", borderRadius: "5px", display: "flex" }}>
          <FormControlLabel
            control={
              <Checkbox color="secondary" size="small" onChange={(event) => {
                setPrintSetting({ ...printSetting, printDate: event.target.checked })
              }} checked={printSetting.printDate} />
            }
            label="日付"
          />
          <FormControlLabel
            control={
              <Checkbox color="secondary" size="small" onChange={(event) => {
                setPrintSetting({ ...printSetting, printFloor: event.target.checked })
              }} checked={printSetting.printFloor} />
            }
            label="階数"
          />
          <FormControlLabel
            control={
              <Checkbox color="secondary" size="small" onChange={(event) => {
                setPrintSetting({ ...printSetting, printConstClass: event.target.checked })
              }} checked={printSetting.printConstClass} />
            }
            label="工種"
          />
          <FormControlLabel
            control={
              <Checkbox color="secondary" size="small" onChange={(event) => {
                setPrintSetting({ ...printSetting, printConstType: event.target.checked })
              }} checked={printSetting.printConstType} />
            }
            label="工種詳細"
          />
          <FormControlLabel
            control={
              <Checkbox color="secondary" size="small" onChange={(event) => {
                setPrintSetting({ ...printSetting, printDescription: event.target.checked })
              }} checked={printSetting.printDescription} />
            }
            label="内容説明"
          />

        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" size="small"
          onClick={(e) => {
            props.onClose(e)
          }} color="inherit">
          キャンセル
        </Button>
        <Button variant="contained" size="small"
          onClick={() => {
            props.onUpdateSetting(printSetting)
          }} color="primary">
          決定
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PrintSetting