import "./Overtime.css"

import CellInput from "../../common/CellInput"

import React from 'react'

const Overtime = (props) => {
  let v = props.value
  let commentDisplay = (props.readOnly ? v?.overtimeComment : (v?.startTime !== v?.defaultStartTime || v?.endTime !== v?.defaultEndTime)) ? 'inline' : 'none'
  return (
    <span style={{ display: "flex", flexWrap: "wrap", width: "100%", alignItems: "stretch" }}>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>勤務時間：</div>
      <div><TimeInput readOnly={props.readOnly} value={v?.startTime ? v?.startTime : ""}
        onChange={(event) => {
          props.onChangeOvertime({ ...v, startTime: event.target.value })
        }} /></div>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "30px" }}>〜</div>
      <div><TimeInput readOnly={props.readOnly} value={v?.endTime ? v?.endTime : ""}
        onChange={(event) => {
          props.onChangeOvertime({ ...v, endTime: event.target.value })
        }} /></div>
      <div style={{ width: "100%", display: commentDisplay }}>理由</div>
      <div style={{ width: "100%", display: commentDisplay, marginLeft: "10px", marginRight: "10px" }}>
        <CellInput readOnly={props.readOnly} line={3} value={v?.overtimeComment ? v?.overtimeComment : ""}
          onChange={(event) => {
            props.onChangeOvertime({ ...v, overtimeComment: event.target.value })
          }} />
      </div>
    </span >
  )
}

const TimeInput = (props) => {
  const convMap = {
    '0': '0',
    '1': '1',
    '2': '2',
    '3': '3',
    '4': '4',
    '5': '5',
    '6': '6',
    '7': '7',
    '8': '8',
    '9': '9',
    '０': '0',
    '１': '1',
    '２': '2',
    '３': '3',
    '４': '4',
    '５': '5',
    '６': '6',
    '７': '7',
    '８': '8',
    '９': '9',
    ':': ':',
    '：': ':',
  }

  const keyCheck = (v) => {
    var v2 = '';
    for (var i = 0; i < v.length; i++) {
      var c = convMap[v[i]]
      if (!c) {
        c = ''
      }
      v2 += c
    }

    return v2
  }

  const checkTime = (v) => {
    let t = v.split(':')
    if (t.length !== 2) {
      return ''
    }

    if (!t[0] || parseInt(t[0]) > 48) {
      return ''
    }

    if (!t[1] || parseInt(t[1]) > 59) {
      return ''
    }

    let str = ('00' + parseInt(t[0])).slice(-2) + ':' + ('00' + parseInt(t[1])).slice(-2)
    return str
  }

  return (
    <CellInput style={{ display: "inline-block", width: "50px", textAlign: "center" }} readOnly={props.readOnly} value={keyCheck(props.value)} length={5}
      onChange={(event) => {
        props.onChange(event)
      }}
      onBlur={(event) => {
        event.target.value = checkTime(event.target.value)
        props.onChange(event)
      }}
    />
  )
}

export default Overtime
