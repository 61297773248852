import './Report.css'

import ConstReportFooter from './report/constReport/ConstReportFooter'
import WorkReportFooter from './report/workReport/WorkReportFooter'
import Header from './common/Header'
import Footer from './common/Footer'
import Content from './common/Content'
import MainContent from './common/MainContent'
import ReportBody from './report/ReportBody'

import { STATUS } from './utils/Utils'

import { sessionUserState } from './hooks/SessionUserState'
import { constReportPrintState } from './hooks/ConstReportPrintState'
import { kyReportPrintState } from './hooks/KyReportPrintState'
import { constReportSaveState } from './hooks/ConstReportSaveState'
import { constReportCheckState } from './hooks/ConstReportCheckState'
import { workReportSaveState } from './hooks/WorkReportSaveState'
import { workReportPrintState } from './hooks/WorkReportPrintState'
import { workReportCheckState } from './hooks/WorkReportCheckState'
import { specialFooterState, WORK_REPORT } from './hooks/SpecialFooterState'
import { reportKeyDataState } from './hooks/ReportKeyDataState'

import 'react-responsive-tabs/styles.css'
import React, { useState, useEffect } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

const Report = (props) => {
    const userData = useRecoilValue(sessionUserState)
    const [query, setQuery] = useState({ userId: null, constId: null, mode: null, today: null, next: null })
    const setConstReportSave = useSetRecoilState(constReportSaveState)  // 工事日報の一時保存
    const setConstReportCheck = useSetRecoilState(constReportCheckState)  // 工事日報の提出
    const setConstReportPrint = useSetRecoilState(constReportPrintState) // 工事日報の印刷
    const setKyReportPrint = useSetRecoilState(kyReportPrintState) // 本日のKY活動の印刷
    const setWorkReportSave = useSetRecoilState(workReportSaveState)  // 業務日報の一時保存
    const setWorkReportCheck = useSetRecoilState(workReportCheckState)  // 業務日報の提出
    const setWorkReportPrint = useSetRecoilState(workReportPrintState) // 業務日報の印刷

    const [workReportPage, setWorkReportPage] = useState("today")   // 業務日報の当日／次の日切り替え
    const [constReportPage, setConstReportPage] = useState("today")   // 工事日報の当日／次の日切り替え

    const [keyData, setKeyData] = useRecoilState(reportKeyDataState)   // データのキー項目を保持
    const [initial, setInitial] = useState(false)
    const footerState = useRecoilValue(specialFooterState)

    const search = useLocation().search
    const queryParameter = queryString.parse(search)
    if (query?.userId !== queryParameter.userId
        || query?.constId !== queryParameter.constId
        || query?.mode !== queryParameter.mode
        || query?.today !== queryParameter.today
        || query?.next !== queryParameter.next) {
        setQuery(queryParameter)
    }

    useEffect(() => {
        let key = { userId: userData.userId, constId: '', mode: null, dates: { today: null, next: null }, constDates: { today: null, next: null } }
        if (query) {
            if (query?.userId) {
                key.userId = query.userId
            }
            if (query?.constId) {
                key.constId = query.constId
            }
            if (query?.mode) {
                key.mode = query.mode
            }
            if (query?.today) {
                key.dates.today = new Date(query.today)
                key.constDates.today = new Date(query.today)
            }
            if (query?.next) {
                key.dates.next = new Date(query.next)
                key.constDates.next = new Date(query.today)
            }
            setKeyData(key)
        } else {
            setKeyData(key)
        }

        setTimeout(() => {
            setInitial(true)
        }, 100)
    }, [userData, query])

    useEffect(() => {
        if (keyData.userId && !keyData.constId && !keyData.mode && !keyData.dates.today && !keyData.dates.next && !initial) {
            setTimeout(() => {
                setInitial(true)
            }, 100)
        }
    }, [keyData])

    return (
        <Content>
            <Header title="日報入力" />
            <MainContent className="Report">
                {initial ? (
                    <ReportBody
                        key="report"
                        workReportPage={workReportPage}
                        constReportPage={constReportPage}
                        {...props}
                    />
                ) : (
                    <React.Fragment />
                )}
            </MainContent>
            <Footer>
                {((!query.mode || query.mode === "workReport") && footerState?.mode === WORK_REPORT) ? (
                    <WorkReportFooter
                        page={workReportPage}
                        onPdfWorkReport={(event) => {
                            setWorkReportPrint(true)
                        }}
                        onSaveTemporary={(event) => {
                            setWorkReportSave(true)
                        }}
                        onSent={(event) => {
                            setWorkReportCheck(true)
                        }}
                        onNext={() => {
                            setWorkReportPage("next")
                        }}
                        onToday={() => {
                            setWorkReportPage("today")
                        }}
                    />
                ) : (
                    <ConstReportFooter
                        page={constReportPage}
                        onPdfConstReport={(event) => {
                            setConstReportPrint(true)
                        }}
                        onPdfKyReport={(event) => {
                            setKyReportPrint(true)
                        }}
                        onSaveTemporary={(event) => {
                            setConstReportSave(true)
                        }}
                        onSent={(event) => {
                            setConstReportCheck(true)
                        }}
                        onNext={() => {
                            setConstReportPage("next")
                        }}
                        onToday={() => {
                            setConstReportPage("today")
                        }}
                    />
                )}
            </Footer>
        </Content>
    )
}

export default Report
