import './Book.css'

import DroppableBar from './DroppableBar'
import PhotoInfo from './PhotoInfo'
import DragItem from './DragItem'
import PrintSetting from './PrintSetting'

import { constPhotoStateFamily } from '../hooks/ConstPhotoState'
import { constListData } from '../hooks/ConstListData'
import { errorState } from '../hooks/ErrorState'

import PdfService from '../../services/PdfService'

import { formatDate } from '../utils/Utils'

import React, { useState, useEffect, useRef, } from 'react'
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil'

import { Rnd } from 'react-rnd'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { Card, CardHeader, CardContent, Avatar, IconButton, FormControlLabel, Checkbox } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import CloseIcon from '@mui/icons-material/Close'

import Slick from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

// 1スライドで扱うページ数
const pagesPerSlide = 2
// 選択中ドットの前後に表示させるドット数
const visibleDots = 10

const Book = (props) => {
    const atom = constPhotoStateFamily(props.data.key)
    const [state, setState] = useRecoilState(atom)
    const [page, setPage] = useState({ current: 1, max: Math.ceil(props.data.photos.length / 3) })
    const [printSetting, setPrintSetting] = useState(false)
    const [windowState, setWindowState] = useState({ x: 100, y: 120, width: 1067, })
    const [pagePhotos, setPagePhotos] = useState([])
    const constList = useRecoilValue(constListData) // 案件一覧データ（マップ構造）
    const setError = useSetRecoilState(errorState) // エラーメッセージ表示用

    const getData = () => {
        return state
    }

    const removeData = (photos) => {
        const photoKeys = [...photos.keys()]

        let newPhotos = state.photos.map((photo) => {
            // 削除対象に含まれていれば
            return (photoKeys.includes(photo.photoId)) ? { ...photo, deleted: true } : { ...photo }
        })

        setState({ ...state, photos: newPhotos })
    }

    const restoreData = (trashPhotos) => {
        setState({
            ...state, photos: state.photos.map((photo) => { return (trashPhotos.has(photo.photoId) ? trashPhotos.get(photo.photoId) : photo) })
        })
    }

    const moveFront = () => {
        props.onClick()
    }

    const writePhoto = (photo) => {
        let photos = state.photos.slice()
        const idx = photos.findIndex((row) => { return row.photoId === photo.photoId })
        photos[idx] = photo
        setState({ ...state, photos: photos })
    }

    const divRef = useRef()

    const ROW_PAR_PAGE = 3
    useEffect(() => {
        let pagePhotos = []
        let photoIdx = 0
        let length = state.photos
            ?.map((row, idx) => {
                if (!row.deleted) {
                    let page = Math.floor(photoIdx / ROW_PAR_PAGE)
                    if (!pagePhotos[page]) {
                        pagePhotos[page] = []
                    }
                    pagePhotos[page].push({ ...row, index: idx })

                    photoIdx = row.pageBreak ? (page + 1) * ROW_PAR_PAGE : photoIdx + 1
                    return 1
                }
                return 0
            }).reduce(function (sum, element) {
                return sum + element;
            }, 0)

        setPagePhotos(pagePhotos)

        // ページ数の再計算
        let max = Math.ceil(length / ROW_PAR_PAGE)
        let current = page.current
        if (current > max) {
            current = max
        }
        setPage({ current: current, max: max })

        props.onWindowStateChanges()
    }, [state.photos])

    useEffect(() => {
        // setPage({ current: 1, max: Math.ceil(props.data.photos.length / ROW_PAR_PAGE) })
        setState({ ...props.data })
    }, [props.data])


    useEffect(() => {
        props.connector[props.data.key] = {
            getData: getData,
            removeData: removeData,
            restoreData: restoreData,
            moveFront: moveFront,
        }
    }, [props, state, page, printSetting, pagePhotos, constList])

    const getPageFormat = (pageNum) => {
        let pageFormat = (pageNum > 0 && pageNum <= page.max) ? `${pageNum} / ${page.max}` : ""

        return (
            <div style={{ textAlign: "center", width: "100%", fontSize: "12px" }}>{pageFormat}</div>
        )
    }

    let project = constList.get(props.constId)
    let subHeader = props.constId ? (
        <div><div>{project.name}</div><div> 工期：{formatDate(project.termFrom)}〜{formatDate(project.termTo)} 担当者：{project.staff}</div></div>
    ) : (<React.Fragment />)

    const handleDrop = (selectPhoto, insertPos, isBreakControl) => {
        let photos = state.photos.slice()

        // ドロップされたアイテムと同じIDのデータを削除
        photos = photos.filter((row, idx) => {
            let find = selectPhoto.photos.has(row.photoId)
            if (find && idx < insertPos) { // ドロップする位置より前に、重複するIDの写真が見つかったら、ドロップ位置を一つ前にずらす
                insertPos--
            }

            return !find
        })

        // ドロップ位置が改ページ直後なら、改ページの位置をドロップアイテムの後ろにずらす
        let pageBreak = false
        if (isBreakControl && insertPos !== 0) {
            pageBreak = photos[insertPos - 1].pageBreak ? true : false
            photos[insertPos - 1] = { ...photos[insertPos - 1], pageBreak: false }
        }

        let idx = 0
        selectPhoto.photos.forEach((value, key) => {
            // 最後の写真なら、改ページの設定値をコピー
            if (idx === selectPhoto.photos.size - 1) {
                value = { ...value, pageBreak: pageBreak }
            }

            photos.splice(idx + insertPos, 0, value)

            idx++
        })

        setState({ ...state, photos: photos })
    }
    return (
        <span className="Book">
            <PrintSetting open={printSetting}
                value={state}
                onClose={(event) => {
                    setPrintSetting(false)
                }}
                onUpdateSetting={(setting) => {
                    setState(setting)
                    setPrintSetting(false)
                }} />
            <Rnd size={{ width: windowState?.width, height: windowState?.height }}
                position={{ x: windowState?.x, y: windowState?.y }}
                enableResizing={false}
                onDragStop={(e, d) => {
                    setWindowState({ ...state.windowState, x: d.x, y: d.y })
                }}
                onClick={(e) => {
                    props.onClick(e)
                }}
                style={{
                    visibility: state.window ? 'visible' : 'hidden', overflow: "hidden", position: "absolute", border: "1px solid #888",
                }}
                minHeight="710px"
                maxHeight="710px"
                maxWidth="1100px"
                minWidth="1100px"
            >
                <Card style={{ width: "100%", height: "100%", }}>
                    <CardHeader
                        avatar={
                            <Avatar aria-label="recipe">
                                {state.title?.substring(0, 1)}
                            </Avatar>
                        }
                        action={
                            <div>
                                <IconButton
                                    onClick={() => {
                                        let project = constList.get(props.constId)
                                        let data = { ...state, photos: state.photos.filter((p) => { return !p.deleted }) }  // 削除データを除く
                                        let printData = { ...data, constId: project.constId, constName: project.name, termFrom: new Date(project.termFrom), termTo: new Date(project.termTo) }
                                        PdfService.download("constphoto", printData)
                                            .catch(error => {
                                                setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
                                            })
                                    }}
                                    onTouchEnd={() => {
                                        let project = constList.get(props.constId)
                                        let data = { ...state, photos: state.photos.filter((p) => { return !p.deleted }) }  // 削除データを除く
                                        let printData = { ...data, constId: project.constId, constName: project.name, termFrom: new Date(project.termFrom), termTo: new Date(project.termTo) }
                                        PdfService.download("constphoto", printData)
                                            .catch(error => {
                                                setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
                                            })
                                    }}
                                >
                                    <PictureAsPdfIcon />
                                </IconButton>
                                <IconButton aria-label="settings"
                                    onClick={(event) => {
                                        setPrintSetting({ ...printSetting, open: true })
                                        event.stopPropagation()
                                    }}
                                    onTouchEnd={(event) => {
                                        setPrintSetting({ ...printSetting, open: true })
                                        event.stopPropagation()
                                    }}
                                >
                                    <MoreVertIcon />
                                </IconButton>
                                <IconButton
                                    onClick={() => {
                                        setState({ ...state, window: false })
                                    }}
                                    onTouchEnd={() => {
                                        setState({ ...state, window: false })
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        }
                        title={state.title}
                        subheader={subHeader}
                        style={{ paddingBottom: "0px" }}
                    />
                    <CardContent style={{ position: "relative", display: "block", height: "100vh" }}
                        onDrag={(event) => { event.stopPropagation() }}
                        onMouseDown={(event) => { event.stopPropagation() }}
                        onMouseMove={(event) => { event.stopPropagation() }}
                        onMouseUp={(event) => { event.stopPropagation() }}
                    >
                        <div ref={divRef} style={{ height: "100%" }}>
                            {pagePhotos.length > 0 ?
                                (<Slick
                                    slidesToShow={pagesPerSlide}
                                    slidesToScroll={pagesPerSlide}
                                    infinite={false}
                                    arrows={true}
                                    dots={true}
                                    customPaging={(slideIndex) => {
                                        // 選択中ドットの前後に設定したドットを表示させる
                                        // slideIndexは、ページではなくスライドのインデックス番号となる
                                        // 本関数はスライダーが移動するイベントの度に実行されるようだ

                                        const currentPage = page.current === 0 ? 1 : page.current

                                        // 比較のためページ番号単位にする
                                        const pageNum = (pagesPerSlide * slideIndex) + 1
                                        const visiblePages = pagesPerSlide * visibleDots
                                        // 表示させる対象範囲のページ
                                        const from = currentPage - visiblePages
                                        const to = currentPage + visiblePages

                                        // 表示させる対象範囲であればvisibleクラスを付与する
                                        let className = ""
                                        if (pageNum >= from && pageNum <= to) {
                                            className = "visible"
                                        }

                                        return (
                                            <button
                                                className={className}
                                                title={pageNum}
                                            >
                                                {pageNum}
                                            </button>)
                                    }}
                                    // rows={3}
                                    swipeToSlide={true}
                                    style={{ position: "relative", top: "0px", bottom: "0px" }}
                                    nextArrow={<NextArrow page={page} connector={props.connector} />}
                                    prevArrow={<PrevArrow page={page} connector={props.connector} />}
                                    afterChange={(index) => {
                                        setPage({ ...page, current: index + 1 })
                                    }}
                                >
                                    {pagePhotos.map((pagePhoto, pageIdx) => {
                                        let lastIndex = pagePhoto[pagePhoto.length - 1].index + 1
                                        return (
                                            <div key={"page-" + pageIdx} >
                                                <div style={{ position: "relative", overflowY: "hidden", maxHeight: "590px", minHeight: "590px", height: "590px" }}>
                                                    {
                                                        pagePhoto.map((photo, idx) => {
                                                            let photoNumber = (pageIdx * ROW_PAR_PAGE) + idx
                                                            let readOnly = (Math.abs(pageIdx - page.current) > 1)
                                                            let display = (Math.abs(pageIdx - page.current) <= 2)
                                                            return display ? (
                                                                <div style={{ border: "1px solid #888" }}>
                                                                    {!photo.blank ? (
                                                                        <DroppableBar key={"droppable-" + photoNumber} insertPos={photo.index} style={{ width: "100%", height: "6px", minHeight: "6px" }}
                                                                            onDropPhoto={(selectPhoto, insertPos) => { handleDrop(selectPhoto, insertPos, idx !== 0) }}
                                                                        />
                                                                    ) : (
                                                                        <React.Fragment />
                                                                    )}

                                                                    <div style={{ position: "relative" }}>
                                                                        <DragItem connector={props.connector}>
                                                                            {!photo.blank ? (
                                                                                <PhotoInfo key={photo.photoId} readOnly={readOnly} type={props.data.key} selectable={true} photo={photo} onChangePhotoInfo={(p) => { writePhoto(p) }} />
                                                                            ) : (
                                                                                <React.Fragment />
                                                                            )}
                                                                        </DragItem>
                                                                        <div className="PageBreakController">
                                                                            <FormControlLabel
                                                                                style={{ marginTop: "-5px" }}
                                                                                control={
                                                                                    <Checkbox size="small" color="secondary"
                                                                                        style={{ transform: [{ scaleX: 0.6 }, { scaleY: 0.6 }] }}
                                                                                        onChange={(event) => {
                                                                                            writePhoto({ ...photo, pageBreak: event.target.checked })
                                                                                        }} checked={photo.pageBreak} />
                                                                                }
                                                                                label={<span style={{ fontSize: "12px" }}>この写真の後で改ページする</span>}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div style={{ border: "1px solid #888", backgroundColor: "white", height: "100%" }}>
                                                                    <img style={{ display: "block", position: "absolute", top: "50%", left: "50%" }} src="images/loading.gif" alt="ロード中"></img>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    <DroppableBar key={"droppable-null-page" + pageIdx} insertPos={lastIndex} style={{ width: "100%", maxHeight: "100%", height: "100%", minHeight: "6px" }}
                                                        onDropPhoto={(selectPhoto, insertPos) => { handleDrop(selectPhoto, insertPos, true) }}
                                                    />
                                                    <div style={{ position: "absolute", marginLeft: "auto", marginRight: "auto", width: "100%", height: "25px", bottom: 0, backgroundColor: "white" }}>{getPageFormat(pageIdx + 1)}</div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    {(pagePhotos.length % 2 !== 0) ? (
                                        <DroppableBar style={{ width: "100%", height: "100%", backgroundColor: "white" }} insertPos={state.photos.length}
                                            onDropPhoto={(selectPhoto, insertPos) => { handleDrop(selectPhoto, insertPos, false) }}
                                        >
                                        </DroppableBar>
                                    ) : (
                                        <React.Fragment />
                                    )}
                                </Slick>) : (
                                    <DroppableBar style={{ width: "100%", height: "100%", backgroundColor: "white" }} insertPos={0}
                                        onDropPhoto={(selectPhoto, insertPos) => { handleDrop(selectPhoto, insertPos, false) }}
                                    >
                                    </DroppableBar>
                                )}
                        </div>
                    </CardContent>
                </Card>
            </Rnd >
        </span >
    )
}

const NextArrow = (props) => {
    let dragStartTime

    return (
        <IconButton className={"slide-arrow next-arrow Pager"} style={{ position: "absolute", top: "300px", right: "5px", zIndex: 1, display: (props.page.current < props.page.max - 1 ? "block" : "none") }} color="primary"
            onClick={(event) => {
                props.onClick(event)
            }}
        >
            <ArrowForwardIosIcon
                onDragEnter={(event) => {
                    dragStartTime = Date.now()
                }}
                onDragOver={(event) => {
                    if (dragStartTime && Date.now() - dragStartTime > 500) {
                        // 500ミリ秒以上経過したら、ドラッグ開始時刻をリセットし、処理を実行する
                        dragStartTime = null;
                        props.onClick()
                    }
                }}
            />
        </IconButton>
    )
}

const PrevArrow = (props) => {
    let dragStartTime

    return (
        <IconButton className={"slide-arrow prev-arrow Pager"} style={{ position: "absolute", top: "300px", left: "5px", zIndex: 1, display: (props.page.current !== 1 ? "block" : "none") }} color="primary"
            onClick={(event) => {
                props.onClick(event)
            }}
        >
            <ArrowBackIosIcon
                onDragEnter={(event) => {
                    dragStartTime = Date.now()
                }}
                onDragOver={(event) => {
                    if (dragStartTime && Date.now() - dragStartTime > 500) {
                        // 500ミリ秒以上経過したら、ドラッグ開始時刻をリセットし、処理を実行する
                        dragStartTime = null;
                        props.onClick()
                    }
                }}
            />
        </IconButton>
    )
}

export default Book
