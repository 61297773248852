import './Good.css'

import { errorState } from '../hooks/ErrorState'
import { userListData } from '../hooks/UserListData'
import { sessionUserState } from '../hooks/SessionUserState'

import GoodService from '../../services/GoodService'

import React, { useState, useEffect } from 'react'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { Paper, IconButton } from '@mui/material'
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt'

const Good = (props) => {
    const [goods, setGoods] = useState([])
    const [visibleMember, setVisibleMember] = useState(false)
    const userData = useRecoilValue(sessionUserState)
    const setError = useSetRecoilState(errorState) // エラーメッセージ表示用

    const load = (id) => {
        if (id) {
            GoodService.load(id)
                .then(
                    (response) => {
                        let data = response.data?.objects
                        if (!data) {
                            data = []
                        }
                        setGoods(data)
                    },
                    (error) => {
                        setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
                    }
                ).catch(
                    (error) => {
                        setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
                    }
                )
        }
    }

    useEffect(() => {
        setTimeout(() => {
            load(props.value)
        }, 100)
    }, [props.value])

    const handleGood = (event) => {
        if (props.readOnly || props.targetUserId === userData.userId) {  // 更新不可 もしくは 対象データが本人の物であった場合は更新しない
            return
        }
        let data = goods.slice()
        let addData = { targetId: props.value, userId: props.userId, targetUserId: props.targetUserId, date: props.date }
        event.stopPropagation()
        GoodService.save(addData)
            .then(
                (response) => {
                    load(props.value)
                },
                (error) => {
                    setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
                }
            ).catch(
                (error) => {
                    setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
                }
            )
    }

    const handleRemoveGood = (event) => {
        if (props.readOnly) {
            return
        }
        let data = goods.find((row) => {
            return row.userId === userData.userId
        })
        event.stopPropagation()
        GoodService.remove(data._id)
            .then(
                (response) => {
                    load(props.value)
                },
                (error) => {
                    setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
                }
            ).catch(
                (error) => {
                    setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
                }
            )
    }

    return (
        props?.value ? (
            <div div className="Good" >
                {goods?.find((row) => { return userData.userId === row.userId }) ? (
                    <IconButton size="small" onClick={handleRemoveGood}>
                        <ThumbUpAltIcon className="ThumbOn" />
                    </IconButton>
                ) : (
                    <IconButton size="small" onClick={handleGood}>
                        <ThumbUpAltIcon className={props.readOnly ? "ThumbReadOnly" : "ThumbOff"} />
                    </IconButton>
                )}
                {
                    goods?.length > 0 ? (
                        <div style={{ fontSize: "12px" }}
                            onMouseEnter={(event) => {
                                setVisibleMember(true)
                                event.stopPropagation()
                            }}
                            onMouseLeave={(event) => {
                                setVisibleMember(false)
                                event.stopPropagation()
                            }}
                            onClick={(event) => {
                                setVisibleMember(!visibleMember)
                                event.stopPropagation()
                            }}
                        >{goods?.length}
                        </div>
                    ) : (
                        <React.Fragment />
                    )
                }
                <span style={{ position: "relative" }}>
                    {
                        visibleMember ? (
                            <GoodMember value={goods} />
                        ) : (
                            <React.Fragment />
                        )
                    }
                </span>
            </div >) : (
            <React.Fragment />
        )
    )
}

const GoodMember = (props) => {
    const userList = useRecoilValue(userListData) // アカウント一覧データ（マップ構造）
    return (
        <Paper elevation={3} className="GoodMember">
            {
                props.value?.map((row) => {
                    return (<div style={{ whiteSpace: "nowrap" }}>{userList.get(row.userId).userName}</div>)
                })
            }
        </Paper>
    )
}

export default Good