import './ConstReportList.css'
import Header from './common/Header'
import Footer from './common/Footer'
import Content from './common/Content'
import MainContent from './common/MainContent'
import InfoBox from './common/InfoBox'
import CellInput from './common/CellInput'
import DateTime from './common/DateTime'
import UserChoiser from './common/UserChoiser'
import ConstSelector from './common/ConstSelector'
import ConstReport from './report/ConstReport'
import ConstReportComments from './report/ConstReportComments'
import { MediaPc, MediaSmartPhone } from './common/Responsive'
import AreaChoiser from './common/AreaChoiser'

import ReportListService from '../services/ReportListService'
import PdfService from '../services/PdfService'

import { getInitialConstReport, convMongoIsoDate } from './utils/Utils'
import { sessionUserState } from './hooks/SessionUserState'
import { errorState } from './hooks/ErrorState'

import React, { useState, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { atom, useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil'
import queryString from 'query-string'

import { Box, IconButton, Card, Paper, Button } from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'

const fmt = (dt) => {
    let y = dt.getFullYear()
    let m = ("00" + (dt.getMonth() + 1)).slice(-2)
    let d = ("00" + dt.getDate()).slice(-2)
    return `${y}-${m}-${d}`
}

const dateAdd = (dt, day) => {
    dt.setDate(dt.getDate() + day)
    return dt
}

const searchConditionState = atom({
    key: 'constReportList.searchCondition',
    default: { constId: '', userId: '', start: fmt(dateAdd(new Date(), -2)), end: fmt(new Date()) },
})

const ConstReportList = (props) => {
    const [constReportList, setConstReportList] = useState([])
    const setError = useSetRecoilState(errorState) // エラーメッセージ表示用
    const [page, setPage] = useState("today")

    const [condition, setCondition] = useRecoilState(searchConditionState)
    const userState = useRecoilValue(sessionUserState)

    const search = useLocation().search
    const queryParameter = queryString.parse(search)

    useEffect(() => {
        setCondition({
            constId: queryParameter.constId ? queryParameter.constId : '',
            userId: queryParameter.userId ? queryParameter.userId : '',
            start: queryParameter.startDate ? fmt(new Date(queryParameter.startDate)) : fmt(dateAdd(new Date(), -2)),
            end: queryParameter.endDate ? fmt(new Date(queryParameter.endDate)) : fmt(new Date()),
            area: queryParameter.area ? queryParameter.area : userState.personalSetting.area
        })
    }, [])

    const loadConstReportList = (key) => {
        ReportListService.loadConstReport(key)
            .then(
                response => {
                    let reportListDatas = response.data.objects.map((rowData) => {
                        return {
                            today: {
                                ...rowData.constReport.constReportResult,
                                photos: rowData.constReport.photo
                            },
                            next: rowData.constReport.constReportPlan,
                            discussion: rowData.constDiscussion
                        }
                    })
                    setConstReportList(reportListDatas)
                },
                error => {
                    setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
                }
            ).catch(error => {
                setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
            })
    }

    const printPdf = () => {
        PdfService.download("constreport", { data: constReportList })
            .catch(error => {
                setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
            })
    }

    return (
        <Content>
            <Header title="工事日報印刷" />
            <MainContent className="ConstReportList">
                <SearchCondition value={condition} onChangeSearchCondition={(condition) => {
                    setConstReportList([])
                    loadConstReportList(condition)
                }} />
                <div className="list">
                    {constReportList.map((r) => {
                        return (
                            <React.Fragment>
                                <ConstReportRow key={r._id} page={page} value={r} onError={(e) => {
                                    setError(e)
                                }} />
                                <hr style={{ border: "2px solid #0000", margin: "0px", width: "calc(100% - 20px)" }} />
                            </React.Fragment>
                        )
                    })}
                </div>
            </MainContent>
            <Footer>
                <Box style={{ paddingRight: "10px" }}>
                    <Button color="inherit" variant="contained" onClick={(event) => {
                        printPdf()
                    }}><PictureAsPdfIcon /><MediaPc>工事日報(PDF)</MediaPc></Button>
                </Box>
                <MediaSmartPhone>
                    <PrevArrow page={page} onClick={() => {
                        setPage("today")
                    }} />

                    <NextArrow page={page} onClick={() => {
                        setPage("next")
                    }} />
                </MediaSmartPhone>
            </Footer>
        </Content>
    )
}

const SearchCondition = (props) => {
    const [searchCondition, setSearchCondition] = useState(props.value)
    return (
        < div style={{ backgroundColor: "white", padding: "5px" }}>
            <InfoBox title={"日付範囲"}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <CellInput key={"start"} type="date" value={searchCondition.start}
                        onChange={(event) => {
                            setSearchCondition({ ...searchCondition, start: event.target.value })
                        }}
                    />
                    <span style={{ verticalAlign: "middle" }}>〜</span>
                    <CellInput key={"end"} type="date" value={searchCondition.end}
                        onChange={(event) => {
                            setSearchCondition({ ...searchCondition, end: event.target.value })
                        }}
                    />
                </div>
            </InfoBox>
            <InfoBox title={"社員"} style={{ width: "400px" }}>
                <UserChoiser value={searchCondition.userId}
                    onChoiseUser={(userId) => {
                        setSearchCondition({ ...searchCondition, userId: userId })
                    }} />
            </InfoBox>
            <InfoBox title={"工事番号"} style={{ width: "600px" }}>
                <ConstSelector value={searchCondition.constId}
                    onChangeConstId={(constId) => {
                        setSearchCondition({ ...searchCondition, constId: constId })
                    }} />
            </InfoBox>
            <InfoBox title={"エリア"}>
                <AreaChoiser value={searchCondition.area} onChange={(event) => {
                    setSearchCondition({ ...searchCondition, area: event.target.value })
                }} />
            </InfoBox>
            <Button
                style={{ marginLeft: "10px" }}
                variant="contained"
                color="inherit"
                onClick={() => {
                    props.onChangeSearchCondition(searchCondition)
                }} >
                検索
            </Button>
        </div >
    )
}

const ConstReportRow = (props) => {
    let [constReport, setConstReport] = useState({ today: {}, next: {} })

    const buildDisplayConstReport = (data) => {
        let today = new Date(data.today.date)
        let next = new Date(data.next.date)
        let d = {
            id: data.today._id,
            constId: data.today.constId,
            accountId: data.today.accountId,
            sendDate: '',
            updateDates: [],
            superiorId: '',    // TODO:ロードできない場合、デフォルトの上長をセットする必要有り
            members: [],
            dates: { today: today, next: next },
            today: getInitialConstReport(today),
            next: getInitialConstReport(next),
        }

        if (data.today) {
            d.sendDate = data.today.sendDate
            d.updateDates = data.today.updateDates
            d.superiorId = data.today.superiorId
            d.today = { ...data.today, date: today }
            d.members = data.today.members
            d.photos = data.today.photos
        }

        if (data.next) {
            d.next = { ...data.next, date: next }
        }

        if (data.discussion) {
            d.discussion = data.discussion
        }

        return d
    }

    useEffect(() => {
        setConstReport(buildDisplayConstReport(props.value))
    }, [props.value])

    // const constReportMemo = useMemo(() => { return (<ConstReport value={constReport} readOnly={true} discussion={true} page={props.page} />) }, [constReport])

    return (
        <div key={props.key} style={{ position: "relative", margin: "5px", display: "flex", flexDirection: "row" }}>
            <Card className="outlinePanel" variant="outlined" >
                <div className="submitDate">
                    <InfoBox title="提出日時">
                        <div><DateTime value={constReport.sendDate} /></div>
                        {constReport.updateDates?.map((r) => {
                            return (<div>再提出：<DateTime value={r} /></div>)
                        })}
                    </InfoBox>
                </div>
                <div style={{ position: "relative" }}>
                    {/* {constReportMemo} */}
                    <ConstReport value={constReport} readOnly={true} discussion={true} page={props.page} />
                    <Pager />
                </div>
                {
                    constReport.discussion && constReport.discussion.discussion && constReport.discussion.discussion.length > 0 ?
                        (<ConstReportComments readOnly={true} initialValue={constReport?.discussion} onError={(error) => {
                            props.onError(error)
                        }} />
                        ) : (null)
                }
            </Card >
        </div >
    )
}

const Pager = (props) => {
    return (
        <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, pointerEvents: "none" }} />
    )
}

const NextArrow = (props) => {
    return (
        <IconButton className={" Pager"} style={{ position: "absolute", display: (props.page === "today" ? "block" : "none"), width: "40px", top: "0px", right: "5px", color: "white" }} aria-label="edit"
            onClick={props.onClick}
        >
            <ArrowForwardIosIcon />
        </IconButton>
    )
}

const PrevArrow = (props) => {
    return (
        <IconButton className={" Pager"} style={{ position: "absolute", display: (props.page === "next" ? "block" : "none"), width: "40px", top: "0px", left: "5px", color: "white" }} aria-label="edit"
            onClick={props.onClick}
        >
            <ArrowBackIosIcon />
        </IconButton>
    )
}

export default ConstReportList
