import './Role.css'

import { roleList } from '../utils/RoleUtils'
import ConfirmationDialog from '../common/ConfirmationDialog'
import { MediaSmartPhone, MediaPc } from '../common/Responsive'

import RoleService from '../../services/RoleService'
import UserService from '../../services/UserService'

import { errorState } from '../hooks/ErrorState'
import { messageState } from '../hooks/MessageState'

import React, { useState, useEffect } from 'react'
import { useSetRecoilState } from 'recoil'
import { Paper, Checkbox, TextField } from '@mui/material'
import { Box, Button } from '@mui/material'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

const Role = (props) => {
    const [roleData, setRoleData] = useState([])
    const [filter, setFilter] = useState('')
    const setError = useSetRecoilState(errorState) // エラーメッセージ表示用

    useEffect(() => {
        props.connector.roles = () => {
            return roleData
        }
    }, [props, roleData])

    let timeoutId
    useEffect(() => {
        Promise.all([
            UserService.findAll(null, null, []),
            RoleService.load()
        ]).then(
            response => {
                let map = new Map()
                let users = response[0].data.objects

                users.forEach((v) => {
                    map.set(v.userId, v)
                })

                let roles = response[1].data.objects

                let list = new Map()
                users.forEach((data) => {
                    list.set(data.userId, { userId: data.userId, name: data.name, groupName: data.groupName, roles: data.roles })
                })

                roles.forEach(role => {
                    if (list.get(role.userId)) {
                        list.set(role.userId, { ...list.get(role.userId), _id: role._id, roles: role.roles })
                    }
                })

                let results = []
                for (let data1 of list.values()) {
                    results.push(data1)
                }

                // state更新
                setRoleData(results)
            },
            error => {
                setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
            }
        ).catch(error => {
            setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
        })
    }, [setError])

    return (
        <div className="Role">
            <div style={{ height: "50px", padding: "5px" }}>
                <TextField size="small" style={{ display: "block" }} onChange={(event) => {

                    if (timeoutId) {
                        clearTimeout(timeoutId)
                    }
                    timeoutId = setTimeout(() => {
                        setFilter(event.target.value)
                    }, 500)
                }} defaultValue={filter}
                    label="検索" />
            </div>
            <TableContainer component={Paper} style={{ display: "flex", height: "calc(100% - 50px)" }}>
                <Table
                    style={{ alignSelf: "flex-start" }}
                    stickyHeader
                    size="small"
                    aria-label="a dense table">
                    <TableHead className="scrollHead">
                        <MediaPc>
                            <TableRow>
                                <TableCell className="name">氏名</TableCell>
                                <TableCell className="groupName">所属部署</TableCell>
                                {roleList.map((role) => {
                                    return (<TableCell className="check">{role.name}</TableCell>)
                                })}
                            </TableRow>
                        </MediaPc>
                        <MediaSmartPhone>
                            <TableRow>
                                <TableCell style={{ textAlign: "left", width: "200px" }}>氏名</TableCell>
                                <TableCell style={{ textAlign: "left" }}>権限</TableCell>
                            </TableRow>
                        </MediaSmartPhone>
                    </TableHead>
                    <TableBody className="scrollBody">
                        {roleData.filter((row) => {
                            return (!filter
                                || (row.userId && row.userId.indexOf(filter, 0) >= 0)
                                || (row.groupName && row.groupName.indexOf(filter, 0) >= 0)
                                || (row.name && row.name.indexOf(filter, 0) >= 0))
                        }).map((row) => {
                            return (
                                <Row key={row.userId} userRole={row} onChangeRole={(data) => {
                                    const d = roleData.slice()
                                    let target = d.find((r) => { return r.userId === row.userId })
                                    target.roles = data.roles
                                    target.change = true

                                    setRoleData(d)
                                }} />
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )

}

const RoleSaveButton = (props) => {
    const [open, setOpen] = useState(false)
    const setError = useSetRecoilState(errorState) // エラーメッセージ表示用
    const setMessage = useSetRecoilState(messageState) // メッセージ表示用

    const save = (roles) => {
        const ids = roleList.map((r) => { return r.id })

        Promise.all(roles.filter((r) => {
            return r.change
        }).map((r) => {
            return {
                _id: r._id,
                userId: r.userId,
                roles: r.roles.filter((role) => { return ids.includes(role) })
            }
        }).map((role) => {
            return RoleService.save(role)
        })
        )
            .then(
                response => {
                    setMessage({ msg: `保存を完了しました。`, open: true, title: '権限設定' })
                    setOpen(false)
                },
                error => {
                    setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
                }
            ).then(
        ).catch(error => {
            setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
        })
    }

    return (
        <React.Fragment>
            <ConfirmationDialog
                dialogTitle="権限保存"
                bodyText={"権限の編集結果を保存します。よろしいですか？"}
                open={open}
                onOk={() => {
                    save(props.connector.roles())
                    setOpen(false)
                }}
                onCancel={() => { setOpen(false) }}
                onClose={() => { setOpen(false) }}
            />

            <Box style={{ paddingRight: "10px" }}>
                <Button variant="contained" color="secondary" onClick={(event) => {
                    setOpen(true)
                }}><SaveAltIcon /><MediaPc>保存</MediaPc></Button>
            </Box>

        </React.Fragment>
    )
}

const Row = (props) => {
    const [userRole, setUserRole] = useState(props.userRole)
    return (
        <React.Fragment>
            <TableRow key={props.key} >
                <MediaPc>
                    <TableCell className="name" >{userRole.name}</TableCell>
                    <TableCell className="groupName" >{userRole.groupName}</TableCell>
                    {roleList.map((role) => {
                        return (<TableCell className="check">
                            <Checkbox defaultChecked={userRole.roles.includes(role.id)}
                                size="middle"
                                color="secondary"
                                onChange={(event) => {
                                    const checked = event.target.checked
                                    let roles = userRole.roles.slice()
                                    if (checked) {
                                        roles.push(role.id)
                                    } else {
                                        roles = userRole.roles.filter((r) => {
                                            return r !== role.id
                                        })
                                    }

                                    let newRoles = { ...userRole, roles: roles }
                                    setUserRole(newRoles)
                                    props.onChangeRole(newRoles)
                                }}
                            />
                        </TableCell>)
                    })}
                </MediaPc>
                <MediaSmartPhone>
                    <TableCell >
                        <span style={{ verticalAlign: "top" }}>
                            <div style={{ fontSize: "20px", }}>{userRole.name}</div>
                            <div style={{ marginTop: "5px", marginLeft: "5px" }}>{userRole.groupName}</div>
                        </span>
                    </TableCell >
                    <TableCell >
                        <div>
                            {roleList.map((role) => {
                                return (<div key={props.key + "[" + role.id + "]"}>
                                    <Checkbox style={{ padding: "7px 0px 7px 0px" }} defaultChecked={userRole.roles.includes(role.id)}
                                        size="middle"
                                        color="secondary"
                                        onChange={(event) => {
                                            const checked = event.target.checked
                                            let roles = userRole.roles.slice()
                                            if (checked) {
                                                roles.push(role.id)
                                            } else {
                                                roles = userRole.roles.filter((r) => {
                                                    return r !== role.id
                                                })
                                            }

                                            let newRoles = { ...userRole, roles: roles }
                                            setUserRole(newRoles)
                                            props.onChangeRole(newRoles)
                                        }}
                                    />
                                    <span style={{ fontSize: "12px", }}>{role.name}</span>
                                </div>)
                            })}
                        </div>
                    </TableCell>
                </MediaSmartPhone>
            </TableRow>
        </React.Fragment>
    )
}

export {
    RoleSaveButton
}

export default Role
