import './SafetyInspection.css'

import CellInput from '../../common/CellInput'

import React, { useState, useEffect } from 'react'

const SafetyInspection = (props) => {
    const [safetyInspection, setSafetyInspection] = useState(() => {
        // 初回レンダリング時のみ処理
        return checkRows(props.value) // 表の最下行に空行を追加する
    })

    useEffect(() => {
        setSafetyInspection(checkRows(props.value))
    }, [props.value])

    return (
        <div className="SafetyInspection">
            <table className={"dataList"} style={{ width: "100%" }}>
                <thead className={"scrollHead"}>
                    <tr>
                        <th className={"text"} style={{ textAlign: "center" }}>内容</th>
                    </tr>
                </thead>
                <tbody className={"scrollBody"}>
                    {safetyInspection.map((row, idx) => {
                        return (
                            <tr key={idx} style={{ width: "100%" }}>
                                <td className={"text"}>
                                    <span>{idx + 1}.</span>
                                    <CellInput value={row.text} readOnly={props.readOnly}
                                        maxlength={60}
                                        onChange={(event) => {
                                            let v = event.target.value
                                            safetyInspection[idx].text = v
                                            setSafetyInspection(checkRows(safetyInspection))
                                            props.onChangeSafetyInspection(trimRow(safetyInspection))
                                        }}
                                    />
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

const isEmpty = (o) => {
    return (o.text === '')
}

const newData = { text: '' }

const trimRow = (values) => {
    return values.slice().filter((row) => { return !isEmpty(row) }).map(v => v)
}
const checkRows = (values) => {
    if (!values) {
        values = []
    }
    values = trimRow(values)
    if (values.length < 3) {
        values.push({ ...newData })
    }
    return values
}


export default SafetyInspection